<script setup>
  import { useRuntimeConfig } from '#imports'
  import { VideoPlayer } from '@videojs-player/vue'
  import 'video.js/dist/video-js.css'
  import YouTube from 'vue3-youtube'

  const config = useRuntimeConfig()
  const strapiUrl = config.public.strapi.url

  const props = defineProps({
    careerSite: { required: true, type: Object },
    mobility: { default: false, type: Boolean }
  })

  const { localVideo, videoThumbnail, videoLegend, youtubeLink, useYoutubeVideo } = toRefs(props.careerSite)
</script>

<template>
  <section class="mx-auto max-w-screen-lg space-y-10 px-4 py-6 lg:space-y-16 lg:py-16 xl:max-w-screen-xl">
    <div class="grid grid-cols-1 content-center gap-6 sm:grid-cols-2 sm:gap-12">
      <div class="video-container" v-if="useYoutubeVideo && youtubeLink">
        <YouTube width="100%" :src="youtubeLink" ref="youtube" />
      </div>

      <video-player
        class="h-80 w-full"
        v-else-if="!useYoutubeVideo && localVideo?.url"
        :src="`${strapiUrl}${localVideo.url}`"
        :poster="videoThumbnail?.url ? `${strapiUrl}${videoThumbnail.url}` : ''"
        controls
        loop
        volume="0.6"
      />

      <div class="content-center" v-if="videoLegend">
        <h3 class="font-special text-xl font-bold">{{ videoLegend }}</h3>
      </div>
    </div>
  </section>
</template>

<style>
  .video-container video,
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
