<script setup>
  const props = defineProps({
    config: Object,
    url: { required: true, type: String }
  })

  const el = ref(null)
  const { height, width } = useElementSize(el)
</script>

<template>
  <div
    ref="el"
    :style="
      config
        ? {
            backgroundImage: url ? `url('${useStrapiMedia(url)}')` : undefined,
            backgroundPosition: `top -${Math.round(config.ratio.top * height)}px left -${Math.max(0, Math.round(config.ratio.left * width - 1))}px`,
            backgroundSize: `${config.ratio.zoom * 100}% auto`
          }
        : {
            backgroundColor: 'rgb(0 0 0 / 0.5)',
            border: '1px solid rgb(244 244 245)'
          }
    "
  ></div>
</template>
