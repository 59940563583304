<script setup>
  const props = defineProps({
    careerSite: { required: true, type: Object }
  })

  function getDefaultPhoto(key) {
    return props.careerSite.employer?.page?.photos?.[key - 1]?.file?.url
  }
</script>

<template>
  <section>
    <div class="flex w-full flex-wrap">
      <PagesCareerIndexPhoto
        class="aspect-[5/3] basis-full sm:basis-1/2"
        :config="careerSite.photosConfig?.photo1"
        :url="careerSite.photo1?.url ?? getDefaultPhoto(1)"
      />
      <PagesCareerIndexPhoto
        class="aspect-[5/3] basis-full sm:basis-1/2"
        :config="careerSite.photosConfig?.photo2"
        :url="careerSite.photo2?.url ?? getDefaultPhoto(2)"
      />
    </div>
    <div class="flex w-full flex-wrap">
      <PagesCareerIndexPhoto
        class="aspect-[20/9] basis-full sm:basis-[58%]"
        :config="careerSite.photosConfig?.photo3"
        :url="careerSite.photo3?.url ?? getDefaultPhoto(3)"
      />
      <PagesCareerIndexPhoto
        class="aspect-[20/9] basis-full sm:basis-[42%]"
        :config="careerSite.photosConfig?.photo4"
        :url="careerSite.photo4?.url ?? getDefaultPhoto(4)"
      />
    </div>
  </section>
</template>
