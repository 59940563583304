<script setup>
  const props = defineProps({
    careerSite: { required: true, type: Object },
    mobility: { default: false, type: Boolean }
  })

  const { query } = useRoute()
  const { find } = useStrapi4()

  const employer = props.careerSite.employer

  const jobs = reactive({
    ...useLazyAsyncData(`career-${employer.id}-jobs`, async () => {
      const res = await find('jobs', {
        fields: ['slug', 'title'],
        filters: {
          careerDisplay: !props.mobility ? true : undefined,
          employer: employer.id,
          mobilityDisplay: props.mobility ? true : undefined,
          published: true
        },
        pagination: { limit: 6, start: 0, withCount: false },
        sort: { validatedAt: 'desc' }
      })
      return res.data
    })
  })
</script>

<template>
  <section class="mx-auto max-w-screen-lg space-y-10 px-4 py-12 lg:space-y-16 lg:py-16 xl:max-w-screen-xl">
    <h2 class="text-center text-xl font-bold sm:text-2xl">Nos offres d’emploi</h2>

    <div class="flex flex-wrap max-lg:flex-col max-lg:space-y-12">
      <div class="flex basis-1/2 items-center justify-center lg:pb-16">
        <div class="space-y-4">
          <h3 class="text-lg font-bold sm:text-xl">3 raisons de nous rejoindre</h3>
          <ul class="space-y-2">
            <template v-for="i in 3" :key="i">
              <li v-if="careerSite[`advantage${i}`]">
                <span :style="{ color: careerSite.primaryColor }">• </span> {{ careerSite[`advantage${i}`] }}
              </li>
            </template>
          </ul>
        </div>
      </div>

      <Loading class="mx-auto max-w-screen-xl px-4 py-16" v-if="jobs.pending" />

      <Error class="mx-auto max-w-screen-xl px-4 py-16" v-else-if="jobs.error" :retry="jobs.refresh" />

      <div class="basis-1/2 space-y-8" v-else>
        <div class="mx-auto max-w-screen-sm space-y-5">
          <NuxtLink
            class="block rounded-lg border-l-[12px] bg-zinc-50 px-8 py-4 shadow duration-300 hover:shadow-md"
            v-for="job in jobs.data"
            :key="job.id"
            :style="{ borderColor: careerSite.primaryColor }"
            :to="`${careerSite.slug}/jobs/${job.slug}${query ? '?' + Object.keys(query).join('&') : ''}`"
          >
            <h3 class="line-clamp-1 font-bold">{{ job.title }}</h3>
          </NuxtLink>
        </div>
        <div class="text-center">
          <NuxtLink :to="`${careerSite.slug}/jobs${query ? '?' + Object.keys(query).join('&') : ''}`">
            <Button class="border-2 !font-sans no-underline hover:shadow" color="link" outline :style="{ borderColor: careerSite.primaryColor }"
              >Voir toutes les offres</Button
            >
          </NuxtLink>
        </div>
      </div>
    </div>
  </section>
</template>
