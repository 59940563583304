<script setup>
  import { useRuntimeConfig } from '#imports'
  const config = useRuntimeConfig()
  const strapiUrl = config.public.strapi.url

  const props = defineProps({
    careerSite: { required: true, type: Object },
    mobility: { default: false, type: Boolean }
  })

  // Fonction helper to get the background image url
  const backgroundImageUrl = team => {
    return team.avatar && team.avatar.url ? `background-image: url(${strapiUrl}${team.avatar.url})` : ''
  }

  // Fonstion helper to get the grid classes
  const gridClasses = computed(() => {
    const teamCount = props.careerSite.teams.length
    if (teamCount > 3) {
      return 'grid-cols-2 sm:grid-cols-3 md:grid-cols-4'
    } else if (teamCount > 2) {
      return 'grid-cols-2 sm:grid-cols-3'
    } else if (teamCount > 1) {
      return 'grid-cols-2'
    } else {
      return 'grid-cols-1'
    }
  })
</script>

<template>
  <section class="mx-auto max-w-screen-lg space-y-10 px-4 py-6 lg:space-y-16 lg:py-16 xl:max-w-screen-xl">
    <h2 class="text-center text-xl font-bold sm:text-2xl">L'équipe qui recrute</h2>

    <div class="max-lg:space-y-12">
      <div class="grid gap-4" :class="gridClasses">
        <div v-for="(team, key) in careerSite.teams" :key="team.id">
          <div class="flex justify-center">
            <div class="space-y-3 text-center">
              <div class="relative mx-auto aspect-square h-28 w-28 rounded-full bg-zinc-300 bg-cover bg-center" :style="backgroundImageUrl(team)">
                <NuxtLink
                  class="absolute bottom-1 right-1 flex h-8 w-8 items-center justify-center rounded-full bg-viking-400 text-white shadow duration-300 hover:bg-viking-500"
                  v-if="team.linkedin"
                  :to="team.linkedin"
                  target="_blank"
                >
                  <Icon type="brands" name="linkedin"></Icon>
                </NuxtLink>
              </div>

              <div class="space-y-2">
                <p class="text-sm font-bold" :style="{ color: careerSite.primaryColor }">{{ team.firstname }} {{ team.lastname }}</p>
                <p class="text-xs">{{ team.job }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
